/* HOME
***********************/

.contentPaddingBottom {
  padding-bottom: 345px;
}
.pageContent {
  padding-top: 3em;
}
/* HOME > Contact & actus 
***********************/
#carte {
  background: url(../img/carte-concept-marbre.jpg) no-repeat top left;
  padding-top: 15rem;
  @include breakpoint(medium down) {
    background-size: 300px;
  }
  
  @include breakpoint(medium) {
    padding-top: 7rem;
  }
  @include breakpoint(large) {
    background-size: 350px;
  }
}

.agences {
  @include breakpoint(medium down) {
    padding-top: 2rem;
  }
  .informations {
    p {
      margin: 0;
      margin-left: 1rem;
      @include font-size(16);
    }
  }
}
.agence {
  margin-bottom: 1rem;
}
.agence-intitule {
  margin-bottom: 10px;
  h3 {
    margin: 0;
    @include font-size(20);
    color: $bleu2;
    font-weight: bold;
  }
}
.agence-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include breakpoint(small down) {
    height: 150px;
  }
}
.agence-information {
  background-color: $bleu;
  color: white;
  font-weight: bold;
  i {
    @include font-size(26);
    vertical-align: middle;
  }
  .adresse {
    padding-bottom: 1rem;
    border-bottom: 4px solid white;
  }
  .horaires {
    padding-top: 1rem;
    text-transform: capitalize;
  }
  .horaire {
    padding-right: 1rem;
    border-right: 2px solid white;
  }
}
.agence-details {
  padding: 1.5rem 2rem;
}
#contactAgences {
  padding-top: 2em;
  padding-bottom: 2em;
}

#actus {
  position: relative;
  .wrap {
    padding: 1.5rem 2rem;
    z-index: 10;
    position: relative;
    border: 16px solid #9badb9;
    border-radius: 0 60px;
  }
}
article {
  .actu-img {
    position: relative;
    &:after {
      width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: .4s;
    }
    &:after {
      content: '';
      z-index: 2;
      height: 100%;
      background-color: transparent;
    }
  }
  p {
    margin: 0;
  }
  &:hover {
    .actu-img {
      &:after {
        background-color: rgba($bleu, .4);
      }
    }
    .lire-plus{
      opacity: 1;
    }
    p {
      color: $bleu2;
    }
  }
  .lire-plus {
    position: absolute;
    z-index: 3;
    left: 50%;
    top: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    transition: .4s;
    text-align: center;
    color: white;
    text-transform: uppercase;
    @include font-size(18);
    opacity: 0;
  }
}
.actuTitle {
  color: #1e388c;
  text-transform: uppercase;
  @include font-size(24);
  font-family: $bold;
  line-height: 1.15em;
  margin-bottom: .75em;
}
.svgWrapper {
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

/* HOME > Expertise & Schéma
***********************/
#skyscraper {
  padding: 3em;
  position: relative;
  background: white;
  box-shadow: 0 0 15px -4px rgba(0, 0, 0, 0.4);
  @include breakpoint(medium) {
    box-shadow: 0 10px 10px -4px rgba(0, 0, 0, 0.4);
  }
  @include breakpoint(medium only) {
    margin-bottom: 10rem;
  }
}
#expertiseSchema,
.fondPelouse {
  background: url(../img/pelouse.jpg) no-repeat;
  background-size: 100%;
  background-position: bottom;
}
#expertiseSchema {
  padding: 2rem 0 15rem;
  @include breakpoint(medium) {
    padding: 5rem 0 3rem;
  }
}
.expertiseListing {
  @include breakpoint(medium down) {
    background-color: $bleu;
    text-align: center;
  }
  &.wrap {
    @include breakpoint(medium down) {
      padding: 1rem 1rem 2rem;
      border-radius: 0 60px;
    }
    @include breakpoint(large) {
      padding: 4em 5em;
      z-index: 2;
      position: sticky;
      top: 5rem;
      bottom: 10rem;
    }
  }
  img {
    @include breakpoint(medium down) {
      display: none;
    }
  }
  h2 {
    background: none;
    padding: 0;
    color: white;
  }
  ul {
    margin: 0 0 0 1.25em;
    @include breakpoint(medium down) {
      display: inline-block;
      text-align: left;
    }
  }
} 
#expertiseWrapperHome {
  position: relative;
  color: white;
  margin-bottom: 2rem;
  @include breakpoint(large) {
    margin-bottom: 18rem;
  }
  li {
    font-size: 1.25em;
    line-height: 2.35;
    font-weight: bold;
  }
}