// Rem output with px fallback
@mixin font-size($size) {
    font-size: #{$size}px; //Fallback in px
    font-size: calculate-rem($size);
}

// Center block
@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

// Clearfix
@mixin clearfix() {
	content: "";
	display: table;
	table-layout: fixed;
}

// Clear after (not all clearfix need this also)
@mixin clearfix-after() {
	clear: both;
}
