/* HEADER 
***********************/
header {
  .site-branding {
    background: url(../img/background-marbre.jpg) no-repeat;
    background-size: cover;
    padding: 4rem 0;
    @include breakpoint(medium) {
      padding: 2em 0;
    }
    .medium-align-spaced {
      @include breakpoint(medium) {
        justify-content: space-around;
      }
    }
    .large-align-justify {
      @include breakpoint(large) {
        justify-content: space-between;
      }
    }
  }
  #slogan {
    @include breakpoint(medium down) {
      padding: 2em 0;
    }
  }
  h1 {
    padding: 0;
    margin: 0;
    color: white;
  }
  #phonenumber {
    color: white;
    font-family: "Montserrat", sans-serif;
    font-size: 1.25em;
    text-align: center;
  }
}
#site-navigation {
  background: #e0e0e0;
  text-align: center;
  ul {
    margin-bottom: 0;
  }
  li {
    display: inline-block;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      height: 2px;
      width: 30px;
      background-color: $gris1;
      @include breakpoint(medium) {
        top: calc(50% - 2px);
        left: 0;
        transform: translate(0, -50%);
        height: 16px;
        width: 2px;
      }
    }
    &:first-child:after {
      content: none;
    }
  }
  a {
    display: inline-block;
    font-family: $bold;
    font-size: 1em !important;
    color: #1e2649 !important;
    text-transform: uppercase;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    padding: .5rem;
    transition: .2s ease;
    &:hover,
    &:active {
      color: #111111;
      border-bottom: 4px solid #1e388c;
    }
  }
  .current-menu-item a {
    color: #111111;
    border-bottom: 4px solid #1e388c;
  }
}
.sub-menu {
  background: #fff;
  margin-top: 60px !important;
}
#bannerSingle {
  background: url(../img/bg-slider-wrapper.jpg) no-repeat center;
  background-size: cover;
  height: 15vh;
}
#single-monument {
  background: white;
  padding: 2em;
}
