#breadcrumbs {
    background-color: $bleu;
    color: white;
    padding: .5rem 1rem;
    @include font-size(16);
    a {
        color: white;
    }
    .breadcrumb_last {
        font-family: $bold;
    }
}