// ---------------------------------------------------------
// Functions
// ---------------------------------------------------------

@function calculate-rem($size) {
   $rem-size: $size / $html-font-size;
   @return #{$rem-size}rem;
}
.no-gutter {
  margin-right: 0;
  margin-left: 0;

  > [class^="col-"],
  > [class*=" col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}
