/* PAGE ARCHIVE */
.entry-header h2.titre {
  padding: 0 !important;
}
.entry-title {
  margin-bottom: 0;
}
.entry-meta {
  font-family: "Montserrat", sans-serif;
  color: #676767;
  font-size: 0.8em;
  a {
    font-family: "Montserrat", sans-serif;
    color: #676767 !important;
    font-size: 0.9em;
  }
}
.entry-content {
  text-align: justify;
}
.post-categories {
  margin: 0 !important;
  li {
    list-style: none !important;
  }
}
.content-area {
  ul {
    margin-left: 1.5em;
  }
  li {
    list-style-image: url(../img/icon-liste-arrow.png);
    font-family: "Montserrat", sans-serif;
    font-size: 0.9em !important;
    color: #111111;
  }
} 
.social-sharing {
  margin-top: 30px;
  .fa {
    font-size: 18px;
  }
  ul {
    margin-left: 0;
    margin-top: 30px;
  }
  li {
    list-style: none;
    display: inline-block;
    a {
      padding: 1em 1.5em;
      background: #c14d75;
      &:hover {
        background: #111111;
        color: #c14d75 !important;
        transition: background .1s linear, color .5s ease;
      }
    }
  }
}
.titre-prev-next-post {
  padding: 30px 0 0 0 !important;
}