/*************************
COLORS
*************************/
$red: #c14d75;
$pink: #c14d75;
$gris: #e0e0e0;
$gris1: #a5b6c1;
$gris2: #676767;
$jaune: #fdc400;
$default: #111111;
$bleu: #1e2649;
$bleu2: #1e388c;
$bleugris : #777c91;

$fa-font-path: '../ressources/fontawesome/webfonts/';

$html-font-size: 16 !default; // px
$body-font-size: 16 !default; // px

$breakpoints: (
  small: 0px,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);