/* FOOTER
***********************/
#tombe {
  position: relative;
  z-index: 3;
  @include breakpoint(medium) {
    text-align: left;
  }
  img {
    margin-top: -260px;
    width: 350px;
    @include breakpoint(medium) {
      margin-top: -225px;
      width: 250px;
    }
    @include breakpoint(large) {
      width: 400px;
      margin-top: -305px;
    }
  }
}
#footer {
  background: url(../img/background-marbre.jpg) no-repeat;
  background-size: cover;
  padding: 2em 0;
  @include breakpoint(medium down) {
    padding: 2rem 0 4rem;
  }
}
.informations-footer {
  text-align: center;
  @include breakpoint(small down) {
    padding-top: 1rem;
  }
  @include breakpoint(medium) {
    text-align: left;
  }
}
.adressefooter {
  color: white;
  font-size: 1.2em;
  margin-bottom: 0;
}
#footerTel {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 2em;
  text-transform: uppercase;
}
#menu-footer {
  margin-bottom: 0;
  @include breakpoint(medium) {
    text-align: right;
  }
  li {
    display: block;
    margin-bottom: .4rem;
    a {
      color: white !important;
      font-family: "Montserrat", sans-serif;
      @include font-size(16);
      &:hover {
        color: #fdc400 !important;
      }
    }
  }
}
