/* SIDEBAR
****************************/
/* line 505, ../sass/site/_custom.scss */
#sidebar {
  ul {
    margin-left: 0;
  }
  li {
    list-style: none;
    a {
      display: block;
      text-transform: none;
      padding: 0.35em 1em;
      color: white !important;
      line-height: auto;
    }
    &.parent {
      background: #1e2649;
      border-bottom: 4px solid white;
      font-family: $bold;
      @include font-size(20);
      > a {
        text-transform: uppercase;
      }
    }
    &.child {
      background: #777c91;
      &:hover {
        background-color: $gris1;
      }
      a {
        @include font-size(16);
        
        &:before {
          content: "\f054";
          font-weight: 900;
          font-family: 'Font Awesome 5 Free';
          @include font-size(14);
          margin-right: .1rem;
          padding-left: .4rem;
        }
      }
    }
  }

  .colori {
    position: relative;
    margin-bottom: 15px * 2;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: 100%;
      border: 10px solid transparent;
    }
    
    &:hover {
      .colori-title {
        opacity: 1;
        visibility: visible;
        right: 1px;
        &:hover {
          opacity: 0;
          visibility: hidden;
        }
      }
      &:after {
        border-color: $bleu;
      }
    }
    img {
      width: 100%;
    }
  }
  .colori-title {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: -10px;
    transform: translate(100%, -50%);
    color: white;
    background-color: $bleu;
    padding: .8rem 1rem;
    font-family: $bold;
    text-transform: uppercase;
    width: max-content;
    opacity: 0;
    visibility: hidden;
    transition: opacity .4s, right .4s;
  }
}

/* SIDEBAR */
.widget {
  font-family: "Montserrat", sans-serif;
  font-size: 0.9em !important;
  ul {
    margin-left: 1.5em;
  }
  li {
    list-style-image: url(../img/icon-liste-arrow.png);
    padding: 4px 0;
    a {
      color: #111111;
    }
  }
}