/* HOME > Slider & bouton
***********************/
#sliderWrapper {
    background: url(../img/bg-slider-wrapper.jpg) no-repeat center;
    background-size: cover;
    padding: 2em 0 5em;
    .owl-stage-outer {
        &:hover {
            cursor: ew-resize;
        }
    }
    .item {
        padding: 4rem;
        @include breakpoint(medium) {
            padding: 1em 2em;
        }
        img {
            border-radius: 50%;
        }
    }
}
.rappelWrapper {
    text-align: center;
    margin-top: -3em;
}

.owl-controls {
    position: absolute;
    top: 50%;
    transform: translate("-50%, 0");
    width: 100%;
    
}
.owl-prev,
.owl-next {
    position: absolute;
    top: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
    color: $gris;
    vertical-align: middle;
    font-size: 1.5em;
    @include breakpoint(medium) {
        transform: translate(-50%, -50%);
    }
    transition: .2s ease-in;
    &:hover {
        color: $bleu;
    }
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        width: 40px;
        height: 40px;
        z-index: -1;
        border-radius: 25px;
        @include breakpoint(medium) {
            width: 50px;
            height: 50px;
        }
    }
}
.owl-prev {
    left: 25px;
    @include breakpoint(medium) {
        left: 0;
    }
    &:after {
        left: calc(50% + 2px);
    }
}
.owl-next {
    right: 25px;
    @include breakpoint(medium) {
        right: 0;
    }
    &:after {
        left: calc(50% - 2px);
    }
}
