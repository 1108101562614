/* PAGE DEMANDE DE DEVIS
****************************/
.formWrapper {
  background: white;
  padding: 1em 2em 2em 2em;
  box-shadow: 0 10px 10px -4px rgba(0, 0, 0, 0.4);
  position: relative;
}
#oiseau {
  position: absolute;
  right: -170px;
  top: 180px;
}
.frm_required {
  color: #ed832f;
}