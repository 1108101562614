/* FORMS
****************************/
/* line 211, ../sass/site/_custom.scss */
form fieldset {
  border: 0;
}

/* line 214, ../sass/site/_custom.scss */
form input[type="text"],
form input[type="email"],
form input[type="tel"],
form textarea {
  background: white;
  border-radius: 3px;
  border: 1px solid #ccc;
  padding-left: 1em;
  font-size: 0.9em;
  color: #111111;
  box-shadow: none;
  font-family: "Montserrat", sans-serif;
}

/* line 227, ../sass/site/_custom.scss */
form input[type="submit"] {
  background: #1e388c;
  color: white;
  padding: 0.5em 1.5em;
  font-size: 1em;
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  border: 0;
  margin: 2em auto 0;
}

/* line 239, ../sass/site/_custom.scss */
.frm_opt_container label {
  font-weight: normal;
}

/* line 242, ../sass/site/_custom.scss */
form label {
  font-weight: bold;
}

/* line 245, ../sass/site/_custom.scss */
.frm_radio,
.frm_checkbox {
  display: inline-block;
  margin-right: 1em;
}

/* line 250, ../sass/site/_custom.scss */
input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #0a0a0a;
}

/* line 253, ../sass/site/_custom.scss */
input::-moz-placeholder {
  /* Firefox 19+ */
  color: #0a0a0a;
}

/* line 256, ../sass/site/_custom.scss */
input:-ms-input-placeholder {
  /* IE 10+ */
  color: #0a0a0a;
}

/* line 259, ../sass/site/_custom.scss */
input:-moz-placeholder {
  /* Firefox 18- */
  color: #0a0a0a;
}

/* line 262, ../sass/site/_custom.scss */
.frm_opt_container {
  margin-bottom: 1rem;
}

/* line 265, ../sass/site/_custom.scss */
.frm_top_container {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: white;
}