/*************************
FONTS
*************************/

@font-face {
    font-family: 'montserratbold';
    src: url('../fonts/montserrat/montserrat-bold-webfont.eot');
    src: url('../fonts/montserrat/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat/montserrat-bold-webfont.woff2') format('woff2'),
         url('../fonts/montserrat/montserrat-bold-webfont.woff') format('woff'),
         url('../fonts/montserrat/montserrat-bold-webfont.ttf') format('truetype'),
         url('../fonts/montserrat/montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratregular';
    src: url('../fonts/montserrat/montserrat-regular-webfont.eot');
    src: url('../fonts/montserrat/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat/montserrat-regular-webfont.woff2') format('woff2'),
         url('../fonts/montserrat/montserrat-regular-webfont.woff') format('woff'),
         url('../fonts/montserrat/montserrat-regular-webfont.ttf') format('truetype'),
         url('../fonts/montserrat/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

$regular: 'montserratregular';
$bold: 'montserratbold';