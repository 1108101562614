/* GALERIE PHOTOS
****************************/
.monumentItem {
  margin-bottom: 1.875rem;
  h2 {
    font-size: 1em;
    color: #676767;
    text-align: center;
    margin: .75em auto;
    transition: .2s ease-in;
  }
  a {
    position: relative;
    border: 2px solid #e0e0e0;
    display: inline-block;
    background: white;
    transition: .2s ease-in;
    &:hover {
      border: 2px solid $bleugris;
      h2 {
        color: $bleu;
      }
    }
  }
}
.introMonument {
  margin: 1.5em auto 2em;
  h2 {
    color: $bleu2;
    text-transform: none;
    border-bottom: 2px solid $bleu2;
    span {
      font-family: $regular;
    }
  }
  h3 {
    color: $bleu2;
    text-transform: none;
    @include font-size(16);
    font-family: $regular;
  }
}