/* BUTTONS 
***********************/
.btn {
  background: #ed832f;
  color: white !important;
  padding: 0.5em 1.5em;
  font-size: 1em;
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  &:hover {
    background: #1e388c;
    color: #fff !important;
    text-decoration: none;
    transition: background .1s linear, color .5s ease;
  }
  span {
    font-family: "Montserrat", sans-serif;
  }
  &.rappel {
    @include font-size(26);
    margin: 0 auto;
    box-shadow: 0 0 25px #676767;
    @include breakpoint(small down) {
      width: 100%;
    }
  }
}
.buttonCenterBorderWrap {
  text-align: center;
}
.buttonCenterBorder {
  background: white;
  margin: 0 auto;
  display: inline-block;
  border-radius: 0 0 30px 30px;
  padding: 0 40px 20px;
}

.myphonenumber {
  background: #ed832f;
  padding: 5px 10px;
  border-radius: 25px;
  font-weight: bold;
  color: white;
  font-size: 20px;
  transition: background .2s linear;
  &:hover {
    background: #1e388c;
    color: #fff !important;
    text-decoration: none;
  }
}

.btn-title {
  display: inline-block;
  background: #1e388c;
  border-radius: 30px;
  padding: 0.25em 1em;
  color: white;
  font-family: $bold;
}