/*************************
CUSTOM CSS
*************************/
body {
    font-family: $regular;
    color: $default;
    margin: 0;
}

p, ul{
  font-size: 0.9em;
}

.titre {
    font-family: $bold;
    font-size: 1.4em !important;
    color:$default;
    padding: 16px 0; /*Top, Right, Bottom, Left */
    margin-bottom: 0;
}

a,
a:visited {
    font-size: 1em;
    color: $default;
}

h1 {
    font-family: $bold;
    font-size: 1.5em;
    color: $bleu2;
    text-transform: uppercase;
}

h2 {
    font-family: $bold;
    font-size: 1.5em;
    color: $bleu2;
    text-transform: uppercase;
    margin: 1em auto;
}

h3 {
    font-family: $bold;
    font-size: 1.25em; /* font-size: 22px; */
    color: $default;
    text-transform: uppercase;
}

h3.alt {
    color: $gris2;
}

h4 {
    font-family: $bold;
    font-size: 20px;
    color: $default;
    margin-bottom: 10px;
}

.gras {
  font-weight: bold;
}

.fond-bleu {
  background: $pink;
  padding-top:60px;
  padding-bottom:60px;
}


/* Small only */
@media screen and (max-width: 39.9375em) {
  body {
    font-size: 16px;
  }

  /* line 661, ../sass/site/_custom.scss */
  .formWrapper,
  #WrapperContentWhite {
    padding: 0;
    box-shadow: none;
  }

  /* line 666, ../sass/site/_custom.scss */
  #tombe img {
    margin-top: -288px;
    padding: 3em;
  }

  /* line 670, ../sass/site/_custom.scss */
  .contentPaddingBottom {
    padding-bottom: 275px;
  }

  /* line 673, ../sass/site/_custom.scss */
  #menu-footer,
  #footerTel {
    text-align: center;
  }

  /* line 691, ../sass/site/_custom.scss */
  #skyscraper {
    padding: 0.625rem;
    box-shadow: none;
  }

  /* line 695, ../sass/site/_custom.scss */
  #contactActus {
    padding-bottom: 0;
  }

  /* line 698, ../sass/site/_custom.scss */
  .nuancier {
    display: none;
  }

  /* line 701, ../sass/site/_custom.scss */
  header {
    text-align: center;
  }
  /* line 703, ../sass/site/_custom.scss */
  header .site-branding {
    padding: 1em 0;
  }
  /* line 706, ../sass/site/_custom.scss */
  header h1 {
    font-size: 1.35em;
  }

  /* line 710, ../sass/site/_custom.scss */
  .menu_title {
    text-align: center !important;
  }
  /* line 712, ../sass/site/_custom.scss */
  .menu_title .bar_logo {
    margin: auto;
  }

  /* line 716, ../sass/site/_custom.scss */
  .rappelWrapper {
    margin-top: 0;
  }
}